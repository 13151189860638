import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface BmiDto extends ExtensibleEntityDto <Guid> {
  minBmi: number
  maxBmi: number
  conclusion: string
  remark: string
}

export class CreateOrUpdateBmiDto {
  public minBmi: number | undefined
  public maxBmi: number | undefined
  public conclusion: string = ''
  public remark: string = ''
}

export class GetListBmiDto extends PagedAndSortedResultRequestDto {
}
